// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React, { useState } from 'react';
import './styles.css'
import RenderBottom from '../RenderBottom';
import { Box, TextField, Typography, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import StripeView from '../Stripe';
import { useResetProjection } from 'framer-motion';
import emailjs from '@emailjs/browser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ContactFormView from '../ContactFormView';

const theme = createTheme({
  palette: {
    primary: {
      main: '#D05EE1', // 设置主要颜色
    },
    secondary: {
      main: '#dc3545', // 设置次要颜色
    },
    // 更多颜色定义...
  },
});


function DonateView() {
  return (
    <div className='donatedivview'>
      <div className='donateview'>
        <div className='title'>
          <div className='left'>捐赠</div>
          <div className='right'>Donation</div>
        </div>
        <div className='contentCN'>CISC是一个非盈利组织，您可以通过
          捐款支持我们的工作，感谢。</div>
        <div className='contentEN marginTop10'>CISC is a non-profit organisation and you can support our work </div>
        <div className='contentEN mairginBottom40'>with a donation. Thank you.</div>
        <StripeView />
      </div>
      <img className='backimg' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/contact/donateBackground.png" />
    </div>
  )
}



// function ContactForm() {
//   const [showSendSuccess, setSendSuccess] = useState(false);
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [name, setName] = useState('');
//   const [way, setWay] = useState('搜索引擎 search engine');
//   const [message, setMessage] = useState('');
//   const [wayId, setWayId] = useState(1);

//   const [errorName, setErrorName] = useState(false)
//   const [errorEmail, setErrorEmail] = useState(false)
//   const [errorMessage, setErrorMessage] = useState(false)

//   const Alert = React.forwardRef(function Alert(props, ref) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
//   });

//   const paramWays = [
//     { id: 1, textCN: '搜索引擎', textEN: 'search engine' },
//     { id: 2, textCN: '学校', textEN: 'university' },
//     { id: 3, textCN: '社交媒体 (如小红书)', textEN: 'social media' },
//     { id: 4, textCN: '微信公众号', textEN: 'wechat' },
//     { id: 5, textCN: '朋友推荐', textEN: 'friend' },
//   ];


//   const checkEmail = () => {
//     const reg = new RegExp(
//       '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$',
//     );
//     setErrorEmail(!(email.match(reg)))
//     return email.match(reg);
//   };

//   const checkName = () => {
//     setErrorName(!(name.length > 0))
//     return name.length > 0;
//   }

//   const checkPhone = () => {
//     return phone.length >= 0;
//   }

//   const checkWay = () => {
//     return way.length > 0;
//   }

//   const checkMessage = () => {
//     setErrorMessage(!(message.length > 20))
//     return message.length > 20;
//   }

//   const send = () => {
//     // return;
//     console.log('way', way);

//     if (!checkName()) {
//       console.log('FAILED...checkName');
//       return;
//     }

//     if (!checkEmail()) {
//       console.log('FAILED...checkEmail');
//       return;
//     }

//     if (!checkPhone()) {
//       console.log('FAILED...checkPhone');
//       return;
//     }

//     if (!checkWay()) {
//       console.log('FAILED...checkWay');
//       return;
//     }

//     if (!checkMessage()) {
//       console.log('FAILED...checkMessage');
//       return;
//     }

//     const user_id = 'U-qSOOCZtST1qr_vn';
//     const service_id = 'service_ux23j3a';
//     const template_id = 'template_13uf1iq';
//     const template_params = {
//       'name': name || '',
//       'email': email || 'No email',
//       'phone': phone || 'No Phone',
//       'way': way || 'No Way',
//       'message': message || 'No Message',
//     };
//     //   fetch('https://api.emailjs.com/api/v1.0/email/send', {
//     //     method: 'post',
//     //     headers: {
//     //       'Content-Type': 'application/json',
//     //     },
//     //     body: JSON.stringify(paramWithToken)
//     //   })
//     //     .then(res => res)
//     //     .then((res) => {
//     //       this.setState({
//     //         showSendSuccess: true,
//     //       })
//     //     }).catch(res => alert(res))
//     // }

//     //test code
//     // console.log('template_params', template_params);
//     // setSendSuccess(true)
//     // return;
//     //end

//     emailjs.send(service_id, template_id, template_params, user_id)
//       .then(function (response) {
//         console.log('SUCCESS!', response.status, response.text);
//         setSendSuccess(true)
//         //
//         setEmail('');
//         setPhone('');
//         setName('');
//         setWay('搜索引擎 search engine');
//         setMessage('');
//         setWayId(1);
//       }, function (error) {
//         console.log('FAILED...', error);
//       });
//   }


//   return (
//     <div className='View3box'>
//       <div className='viewHeader'>
//         <div>
//           <div className='textheaderContactus'>
//             联系我们
//           </div>
//         </div>
//         <div className='textEN'>
//           <div className='textENContact'>Contact</div>
//           <div className='textENUs'>Us</div>
//         </div>
//       </div>
//       <div className='viewContentWhite'>


//         <div className='SectionName MarginTop20'>
//           <div className='sectionLeft'>
//             <div className='SectionNameIn'>
//               <div className='textName'>称呼</div>
//               <div className='textStar'>*</div>
//             </div>
//             <div className='textNameEN'>Name</div>
//           </div>
//           <Box
//             sx={{
//               width: 500,
//               maxWidth: '100%',
//               height: 50,
//             }}
//           >
//             <ThemeProvider theme={theme}>
//               <TextField className='nameTextField' fullWidth id="name"
//                 // style={{height: '50px', fontSize: '16px'}}
//                 // variant="filled"
//                 // size="small"
//                 value={name}
//                 onChange={(event) => {
//                   setName(event.target.value)
//                 }}
//                 color={'primary'}
//                 maxRows={1}
//                 type="name"
//                 name="name"
//                 error={errorName}
//                 // helperText={errorName && 'Please enter a valid name.'}
//                 onBlur={() => {
//                   setErrorName(!checkName())
//                 }}
//                 FormHelperTextProps={{ sx: { fontSize: '10px' } }}
//                 InputLabelProps={{ fontSize: '15px' }}
//                 InputProps={{ sx: { fontSize: '15x', height: '45px' } }}
//                 label={
//                   <Typography variant="body1" fontSize={10}>
//                     请填写一个您的称呯即可
//                   </Typography>
//                 }
//               // sx={{
//               //   height: '45px',
//               // }}
//               />
//             </ThemeProvider>
//           </Box>
//         </div>
//         <div className='SectionName MarginTop20'>
//           <div className='sectionLeft'>
//             <div className='SectionNameIn'>
//               <div className='textName'>电子邮件</div>
//               <div className='textStar'>*</div>
//             </div>
//             <div className='textNameEN'>Email Address</div>
//           </div>
//           <Box
//             sx={{
//               width: 500,
//               maxWidth: '100%',
//               height: 50,
//             }}
//           >
//             <ThemeProvider theme={theme}>
//               <TextField className='nameTextField' fullWidth id="email"
//                 value={email}
//                 onChange={(event) => {
//                   setEmail(event.target.value)
//                 }}
//                 color={'primary'}
//                 maxRows={1}
//                 type="email"
//                 name="email"
//                 label={
//                   <Typography variant="body1" fontSize={10}>
//                     您的个人联系方式将严格保密
//                   </Typography>
//                 }
//                 error={errorEmail}
//                 // helperText={errorEmail && 'Please enter a valid email address.'}
//                 onBlur={() => {
//                   setErrorEmail(!checkEmail())
//                 }}
//                 FormHelperTextProps={{ sx: { fontSize: '10px' } }}
//                 InputLabelProps={{ fontSize: '15px' }}
//                 InputProps={{ sx: { fontSize: '15px', height: '45px' } }}
//               />
//             </ThemeProvider>
//           </Box>
//         </div>
//         <div className='SectionName MarginTop20'>
//           <div className='sectionLeft'>
//             <div className='SectionNameIn'>
//               <div className='textName'>联系电话</div>
//             </div>
//             <div className='textNameEN'>Phone</div>
//           </div>
//           <Box
//             sx={{
//               width: 500,
//               maxWidth: '100%',
//               height: 50,
//             }}
//           >
//             <ThemeProvider theme={theme}>
//               <TextField className='nameTextField' fullWidth id="phone"
//                 // style={{height: '50px', fontSize: '16px'}}
//                 value={phone}
//                 onChange={(event) => {
//                   setPhone(event.target.value)
//                 }}
//                 color={'primary'}
//                 maxRows={1}
//                 type="phone"
//                 name="phone"
//                 FormHelperTextProps={{ sx: { fontSize: '10px' } }}
//                 InputLabelProps={{ fontSize: '15px' }}
//                 InputProps={{ sx: { fontSize: '15px', height: '45px' } }}
//                 label={
//                   <Typography variant="body1" fontSize={10}>
//                     您的个人联系方式将严格保密
//                   </Typography>
//                 }
//               />
//             </ThemeProvider>
//           </Box>
//         </div>
//         <div className='SectionName MarginTop20 alignStart'>
//           <div className='sectionLeft'>
//             <div className='SectionNameIn MarginTop10'>
//               <div className='textName'>从哪了解到我们</div>
//               <div className='textStar'>*</div>
//             </div>
//             <div className='textNameEN'>Where do you hear about us?</div>
//           </div>
//           <div className='ways'>
//             {
//               paramWays.map((item) => (
//                 <div className='way' onClick={() => { setWayId(item.id); setWay(item?.textCN + item?.textEN) }}>
//                   {item.id === wayId ? <div className='pot'></div> : <div className='potUnSelect'></div>}
//                   <div className='textCN'>{item?.textCN}</div>
//                   <div className='textEN'>{item?.textEN}</div>
//                 </div>
//               ))
//             }

//           </div>
//         </div>
//         <div className='SectionName MarginTop20'>
//           <div className='sectionLeft'>
//             <div className='SectionNameIn'>
//               <div className='textName'>您的咨询内容或感兴趣的项目</div>
//               <div className='textStar anotherStar'>*</div>
//             </div>
//             <div className='textNameEN'>Your enquiry</div>
//           </div>
//           <Box
//             sx={{
//               width: 500,
//               maxWidth: '100%',
//               height: 50,
//             }}
//           >
//             <ThemeProvider theme={theme}>
//               <TextField className='nameTextField' fullWidth id="message" multiline
//                 rows={6}
//                 value={message}
//                 onChange={(event) => {
//                   setMessage(event.target.value)
//                 }}
//                 color={'primary'}
//                 type="message"
//                 name="message"
//                 // InputLabelProps={{ fontSize: '10px' }}
//                 label={
//                   <Typography paragraph variant="body1" fontSize={10} sx={{ flexWrap: 'wrap' }}>
//                     请简单描述您的情况，
//                     至少20个字，谢谢
//                   </Typography>
//                   // <div className='inquiryInputText'>为了更好给予支持，请简单描述您的情况，至少20个字，谢谢</div>
//                 }
//                 error={errorMessage}
//                 // helperText={errorMessage && 'Please enter Your enquiry'}
//                 onBlur={() => {
//                   setErrorMessage(!checkMessage())
//                 }}
//               // FormHelperTextProps={{ sx: { fontSize: '16px' } }}
//               />
//             </ThemeProvider>
//           </Box>
//         </div>
//         <div className='viewDivContactUs'>
//           <div className='viewContactUs' onClick={send}>
//             <div className='textContactUs'>Send</div>
//           </div>
//         </div>
//       </div>
//       <WXView />
//       <Snackbar
//         open={showSendSuccess}
//         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//         onClose={() => { setSendSuccess(false) }}
//         message="Send Success, Thank you"
//         sx={{ marginTop: '100px' }}
//         severity="success"
//         autoHideDuration={2000}
//       >
//         <Alert severity="success" sx={{ width: '100%' }} slotProps={{ closeButton: { fontSize: '16px', height: '30px', width: '30px' }, closeIcon: { fontSize: '16px', height: '30px', width: '30px' } }} action={null}>
//           Send Success, Thank you
//         </Alert>
//       </Snackbar>
//     </div >
//   );
// }

function WXView() {
  return (
    <div className='wxview'>
      <div>
        <div className='text1'>通过微信公众号</div>
        <div className='text1'>
          联系我们
        </div>
      </div>
      <div className='viewqrcode'>
        <img className='qrcode' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/qrcodebk.png" />
      </div>
      <div>
        <div className='text2'>关注CISC微信公众号</div>
      </div>
    </div>
  );
}


function ContactUs() {
  const handlePPClick = () => {
    console.log('xx pp click');
  };


  return (
    <section>
      <div className='ViewContact'>
        <img className='backImage' src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/contact/contactUsBackground.png' />
        <div className='ViewContainer'>
          <div className='ViewContainerLeft'>
            {ContactFormView(false)}
          </div>
          <div className='ViewContainerRight'>
            <DonateView />
          </div>
        </div>
        <div className='ViewBottom'>
          <RenderBottom handlePPClick={handlePPClick} />
        </div>
      </div>
    </section>
  );
}
export default ContactUs;