import React, { useState, useEffect } from "react";
import './styles.css'
import RootStore, { setPageState } from "../../../Store/RootStore";
import StripeView from "../../View/ViewStripe";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, TextField, Typography, MenuItem } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import emailjs from '@emailjs/browser';
import ViewContactMobile from "../../View/ViewContact";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const theme = createTheme({
  palette: {
    primary: {
      main: '#D05EE1', // 设置主要颜色
    },
    secondary: {
      main: '#dc3545', // 设置次要颜色
    },
    // 更多颜色定义...
  },
});

export default function MobileHomePage() {

  const imgsHeight = RootStore.clientWidth * 3144 / 1500;



  return <div className="viewMobileHome" >
    {ViewHome()}
    {ViewWhoWeAre()}
    {ViewOurMission()}
    {ViewSupportService()}
    {ViewContact()}
    {ViewDonate()}
    {ViewHomeBottom()}
    {/* {ViewSecondBottom()} */}
    {ViewContactMobile(2)}
    <div className='rightView'>
      {/* {ContactUsButton(true, () => {setOpen(true)})} */}
    </div>
  </div>;
}


function ViewHome() {
  const handleContact = () => {
    window.scrollTo(0, document.documentElement.scrollHeight - 2250 * document.documentElement.clientWidth / 375 || document.body.scrollHeight - 2250 * document.documentElement.clientWidth / 375);
  }

  const imgsHeight = RootStore.clientWidth * 3364 / 1500;
  const imgurl = "https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/home/1.png";
  return (
    <div className="container">
      <div className="divImageBack">
        <div className="backimgonediv" style={{ height: imgsHeight }}>
          <img src={imgurl} className="backimgone" style={{ height: imgsHeight }} />
        </div>
      </div>
      <div className="contentText viewhome" style={{ height: imgsHeight }}>
        <div className="headerCN">中国海外留学生心理支持中心</div>
        <div className="headerEN">Chinese International Students </div>
        <div className="headerEN"><span className="headerEN purpleText">Wellbeing Support</span> Centre</div>
        <div className="text11">
        欢迎来到CISC，这里是为中国海外留学生提供全方位心理支持和身心发展的家园！
        </div>
        <div className="text22">A very warm welcome to CISC! <br />A caring home for Chinese international students dedicated to their mental health and personal growth!
        </div>
        <div className="buttoncontact" onClick={handleContact}>
          <div className="textcontact">Contact us</div>
        </div>
      </div>
    </div>
  )
}

function ViewWhoWeAre() {
  const handleMore = () => {
    setPageState('2')
  }
  const imgsHeight = RootStore.clientWidth * 4276 / 1500;
  const imgurl = "https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/home/2.png";
  return (
    <div div className="container">
      <div className="divImageBack">
        <div className="backimgonediv" style={{ height: imgsHeight }}>
          <img src={imgurl} className="backimgone" style={{ height: imgsHeight }} />
        </div>
      </div>
      <div className="contentText viewwhoweare" style={{ height: imgsHeight }}>
        <div className="title">我们是谁</div>
        <div className="textCN text1">CISC是一家非营利型社区服务机构，专家团队包括具备多项资格和认证且经验丰富的心理咨询师，生命教练和营养师，致力于给在英留学生提供在文化适应、学业支持和身心健康等方面的心理护航。</div>
        <div className="textEN text2">CISC is a not-for-profit Community Interest Company. We are proud to have a team of international experts from diverse backgrounds who are fully qualified and insured to practice an extensive range of modalities, including psychotherapy, life coaching, and nutritional therapy.
        </div>
        <div className="buttonmore" onClick={handleMore}>
          <div className="textmore">查看更多<span className="textMoreEN">more</span></div>
        </div>
      </div>
    </div>
  )
}

function ViewOurMission() {
  const handleMore = () => {
    setPageState('2')
  }
  const imgsHeight = RootStore.clientWidth * 6270 / 1500;
  const imgurl = "https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/home/3.png";
  return (
    <div div className="container">
      <div className="divImageBack">
        <div className="backimgonediv" style={{ height: imgsHeight }}>
          <img src={imgurl} className="backimgone" style={{ height: imgsHeight }} />
        </div>
      </div>
      <div className="contentText viewourmission" style={{ height: imgsHeight }}>
        <div className="title titlemission">我们的使命</div>
        <div className="textCN text3">促进中国海外留学生的身体，心理和情绪健康与人生的全面发展。</div>
        <div className="textEN text4">To promote physical, mental and emotional well-being among Chinese international students in the UK.</div>
        <div className="textCN text5">帮助中国海外留学生实现学业、职业与个人发展的初衷。</div>
        <div className="textEN text6">To help Chinese international students achieve their academic aspirations and personal aims that have brought them to the UK.</div>
        <div className="textCN text7">给予中国海外留学生赋能与支持，帮助他们进一步探索人生与自我发现。</div>
        <div className="textEN text8">To empower and support Chinese international students in their personal growth and self-discovery journey.</div>
        <div className="buttonmore" onClick={handleMore}>
          <div className="textmore">查看更多<span className="textMoreEN">more</span></div>
        </div>
      </div>
    </div>
  )
}

function ViewSupportService() {
  const handleMore = () => {
    setPageState('3')
  }
  const imgsHeight = RootStore.clientWidth * 5842 / 1500;
  const imgurl = "https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/home/4.png";
  return (
    <div div className="container">
      <div className="divImageBack">
        <div className="backimgonediv" style={{ height: imgsHeight }}>
          <img src={imgurl} className="backimgone" style={{ height: imgsHeight }} />
        </div>
      </div>
      <div className="contentText viewsupport" style={{ height: imgsHeight }}>
        <div className="title titlesupport">支持与服务</div>
        <div className="textssCN textss1">免费、专业、保密的心理测评</div>
        <div className="textssEN textss2">Free and Confidential Emotional Wellbeing Assessment</div>
        <div className="textssCN textss3">免费一对一心理支持服务</div>
        <div className="textssEN textss4">Free One-to-One Emotional<br />
          Wellbeing Support</div>
        <div className="textssCN textss5">文化适应和学术引导</div>
        <div className="textssEN textss6">Cultural Adaptation<br />
          & Academic Guidance</div>
        <div className="textssCN textss7">心理支持与个人成长工作坊</div>
        <div className="textssEN textss8">Emotional Wellbeing<br />
          & Self Development Workshops</div>
        <div className="buttonmore" onClick={handleMore}>
          <div className="textmore">查看更多<span className="textMoreEN">more</span></div>
        </div>
      </div>
    </div>
  )
}

function ViewDonate() {
  const imgsHeight = RootStore.clientWidth * 2394 / 1500;
  const imgurl = "https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/home/5.png";
  return (
    <div div className="container">
      <div className="divImageBack">
        <div className="backimgonediv" style={{ height: imgsHeight }}>
          <img src={imgurl} className="backimgone" style={{ height: imgsHeight }} />
        </div>
      </div>
      <div className="contentText viewdonate" style={{ height: imgsHeight }}>
        <div className="title titledonate">捐赠</div>
        <div className="textCN textdt1" >CISC是一个非盈利组织，您可以通过捐款支持我们的工作，感谢。</div>
        <div className="textdtEN textdt2" >CISC is a non-profit organisation and you can support our work with a donation. <br />Thank you.</div>
        <div className="viewDonate">
          {StripeView()}
        </div>
      </div>
    </div>
  )
}

function ViewHomeBottom() {
  const email = 'support@ciscic.org.uk'

  const ppclick = (event) => {
    setPageState('5');
    // 将页面滚动到顶部
    window.scrollTo(0, 0);
  };

  const imgsHeight = RootStore.clientWidth * 1026 / 1500;
  const imgurl = "https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/home/66.png";
  return (
    <div div className="container">
      <div className="divImageBack">
        <div className="backimgonediv" style={{ height: imgsHeight }}>
          <img src={imgurl} className="backimgone" style={{ height: imgsHeight }} />
        </div>
      </div>
      <div className="contentText viewhomebottom" style={{ height: imgsHeight }}>
        <img className="footerIconAddress" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/contact/address.png" />
        <img className="footerIconEmail" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/contact/email.png" />
        <div className="textBottom textbottom3">Email Address:<a className="email" href={`mailto:${email}`}>{email}</a></div>
        <div className="textBottom textbottom4">Address: 8 Northumberland Avenue, London, WC2N 5BY, United Kingdom</div>
        <div className="footerText2 foothover" onClick={ppclick}>Privacy Policy</div>
        <div className="footerText2">
          Copyright © 2019 - 2024 Chinese International Students Well-being Support Centre CIC (Company no.12249552) - All Rights Reserved.
        </div>
      </div>
    </div>
  )
}

function ViewContact() {
  const imgsHeight = RootStore.clientWidth * 5460 / 1500;
  const imgurl = "https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/home/contact.png";
  return (
    <div div className="container">
      <div className="imgdivcontact">
        <img src={imgurl} className="imgcontact" />
      </div>
      <div className="purplepot">

      </div>
      <div className="contentText viewcontact" style={{ height: imgsHeight }}>
        <div className="title titlecontact">联系我们</div>
        {ContactForm()}
        <div className="viewQRCode">
          <div className="textQRCodeTitle">通过微信公众号<br/>关注我们</div>

          <div className="viewdivImage">
            <img className="imgQRCode" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/qrcodebk.png" />
          </div>
          <div className="textQRCode">CISC微信公众号</div>
        </div>
      </div>
    </div>
  )
}


function ViewSecondBottom() {

  const ppclick = (event) => {
    setPageState('5');
    // 将页面滚动到顶部
    window.scrollTo(0, 0);
  };

  const email = 'support@ciscic.org.uk'


  return (
    <>
      <div className="secondbottom">
        {/* <div className="textBottom textbottom1">中国海外留学生心理支持中心 CISC</div>
                <div className="textBottom textbottom2">Chinese International Students Wellbeing Support Centre</div> */}
        <img className="footerIconAddress" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/contact/address.png" />
        <img className="footerIconEmail" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/contact/email.png" />
        <div className="textBottom textbottom3">Email Address:<a className="email" href={`mailto:${email}`}>{email}</a></div>
        <div className="textBottom textbottom4">Address: 8 Northumberland Avenue, London, WC2N 5BY, United Kingdom</div>
        <div className="viewQRCode">
          <div className="viewdivImage">
            <img className="imgQRCode" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/qrcodebk.png" />
          </div>
          <div className="textQRCode">CISC微信公众号</div>
        </div>
        <div className="footerText2 foothover" onClick={ppclick}>Privacy Policy</div>
        <div className="footerText2">
          Copyright © 2019 - 2024 Chinese International Students Well-being Support Centre CIC (Company no.12249552) - All Rights Reserved.
        </div>

      </div>

    </>
  );
}

function ContactForm() {
  const [showSendSuccess, setSendSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [way, setWay] = useState('搜索引擎 search engine');
  const [message, setMessage] = useState('');
  const [wayId, setWayId] = useState(0);

  const [errorName, setErrorName] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [errorWay, setErrorWay] = useState(false)
  const [loading, setLoading] = useState(false);

  useEffect(() => { setErrorWay(!(way.length > 0)) }, [way, wayId])
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const paramWays = [
    { id: 1, textCN: '搜索引擎', textEN: 'search engine' },
    { id: 2, textCN: '学校', textEN: 'university' },
    { id: 3, textCN: '社交媒体 (如小红书)', textEN: 'social media' },
    { id: 4, textCN: '微信公众号', textEN: 'wechat' },
    { id: 5, textCN: '朋友推荐', textEN: 'friend' },
  ];


  const checkEmail = () => {
    const reg = new RegExp(
      '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$',
    );
    setErrorEmail(!(email.match(reg)))
    return email.match(reg);
  };

  const checkName = () => {
    setErrorName(!(name.length > 0))
    return name.length > 0;
  }

  const checkPhone = () => {
    return phone.length >= 0;
  }

  const checkWay = () => {
    setErrorWay(!(way.length > 0 && wayId > 0))
    return way.length > 0 && wayId > 0;
  }

  const checkMessage = () => {
    setErrorMessage(!(message.length > 20))
    return message.length > 20;
  }

  const send = () => {
    // return;
    console.log('way', way);

    if (!checkName()) {
      console.log('FAILED...checkName');
      return;
    }

    if (!checkEmail()) {
      console.log('FAILED...checkEmail');
      return;
    }

    if (!checkPhone()) {
      console.log('FAILED...checkPhone');
      return;
    }

    if (!checkWay()) {
      console.log('FAILED...checkWay');
      return;
    }

    if (!checkMessage()) {
      console.log('FAILED...checkMessage');
      return;
    }

    const user_id = 'U-qSOOCZtST1qr_vn';
    const service_id = 'service_1qqe9jt';
    const template_id = 'template_13uf1iq';
    const template_params = {
      'name': name || '',
      'email': email || 'No email',
      'phone': phone || 'No Phone',
      'way': way || 'No Way',
      'message': message || 'No Message',
    };
    setLoading(true);

    emailjs.send(service_id, template_id, template_params, user_id)
      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
        setSendSuccess(true)
        //
        setEmail('');
        setPhone('');
        setName('');
        setWay('搜索引擎 search engine');
        setMessage('');
        setWayId(0);
        setLoading(false);

      }, function (error) {
        console.log('FAILED...', error);
        setLoading(false);

      });
  }


  return (
    <div className='ViewContactFormViewMobile'>
      <div className='viewContentWhite'>
        <div className='SectionName'>
          <div className='SectionNameIn'>
            <div className='textName'>称呼</div>
            <div className='textStar'>*</div>
          </div>
          <div className='textNameEN'>Name</div>
          <Box
            sx={{
              width: '670rem',
              height: 50,
              marginTop: '20rem',
              marginBottom: '40rem',
            }}
          >
            <ThemeProvider theme={theme}>
              <TextField className='nameTextField' fullWidth id="name"
                // style={{height: '50px', fontSize: '16px'}}
                // variant="filled"
                // size="small"
                value={name}
                onChange={(event) => {
                  setName(event.target.value)
                  console.log(event.target.value)
                }}
                color={'primary'}
                maxRows={1}
                type="name"
                name="name"
                error={errorName}
                // helperText={errorName && 'Please enter a valid name.'}
                onBlur={() => {
                  setErrorName(!checkName())
                }}
                FormHelperTextProps={{ sx: { fontSize: '10px' } }}
                InputLabelProps={{ fontSize: '15px' }}
                InputProps={{ sx: { fontSize: '15px', height: '45px', backgroundColor: '#F9F9F9', borderRadius: '20rem' } }}
                label={
                  <Typography variant="body1" fontSize={'15px'} align={'center'} mt={'-3.0px'} >
                    请填写一个您的称呯即可
                  </Typography>
                }
              // sx={{
              //   height: '45px',
              // }}
              />
            </ThemeProvider>
          </Box>
        </div>
        <div className='SectionName MarginTop20'>
          <div className='sectionLeft'>
            <div className='SectionNameIn'>
              <div className='textName'>电子邮件</div>
              <div className='textStar'>*</div>
            </div>
            <div className='textNameEN'>Email Address</div>
          </div>
          <Box
            sx={{
              width: '670rem',
              height: 50,
              marginTop: '20rem',
              marginBottom: '40rem',
            }}
          >
            <ThemeProvider theme={theme}>
              <TextField className='nameTextField' fullWidth id="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
                color={'primary'}
                maxRows={1}
                type="email"
                name="email"
                label={
                  <Typography variant="body1" fontSize={'15px'} align={'center'} mt={'-3.0px'} >
                    请如实填写，否则无法联系到您
                  </Typography>
                }
                error={errorEmail}
                // helperText={errorEmail && 'Please enter a valid email address.'}
                onBlur={() => {
                  setErrorEmail(!checkEmail())
                }}
                FormHelperTextProps={{ sx: { fontSize: '10px' } }}
                InputLabelProps={{ fontSize: '15px' }}
                InputProps={{ sx: { fontSize: '15px', height: '45px', backgroundColor: '#F9F9F9', borderRadius: '20rem' } }}
              />
            </ThemeProvider>
          </Box>
        </div>
        <div className='SectionName MarginTop20'>
          <div className='sectionLeft'>
            <div className='SectionNameIn'>
              <div className='textName'>联系电话</div>
            </div>
            <div className='textNameEN'>Phone</div>
          </div>
          <Box
            sx={{
              width: '670rem',
              height: 50,
              marginTop: '20rem',
              marginBottom: '40rem',
            }}
          >
            <ThemeProvider theme={theme}>
              <TextField className='nameTextField' fullWidth id="phone"
                // style={{height: '50px', fontSize: '16px'}}
                value={phone}
                onChange={(event) => {
                  setPhone(event.target.value)
                }}
                color={'primary'}
                maxRows={1}
                type="phone"
                name="phone"
                FormHelperTextProps={{ sx: { fontSize: '10px' } }}
                InputLabelProps={{ fontSize: '15px' }}
                InputProps={{ sx: { fontSize: '15px', height: '45px', backgroundColor: '#F9F9F9', borderRadius: '20rem' } }}
                label={
                  <Typography variant="body1" fontSize={'15px'} align={'center'} mt={'-3.0px'} >
                    如邮件无法联系到您，会尝试短信联系
                  </Typography>
                }
              />
            </ThemeProvider>
          </Box>
        </div>
        <div className='SectionName'>
          <div className='sectionLeft'>
            <div className='SectionNameIn MarginTop10'>
              <div className='textName'>从哪了解到我们</div>
              <div className='textStar'>*</div>
            </div>
            <div className='textNameEN'>Where do you hear about us?</div>
          </div>
          <div className={errorWay ? 'ways waysError' : 'ways'}>
            {
              paramWays.map((item) => (
                <div className='way' onClick={() => { setWayId(item.id); setWay(item?.textCN + item?.textEN) }}>
                  {item.id === wayId ? <div className='pot'></div> : <div className='potUnSelect'></div>}
                  <div className={errorWay ? 'textCN textError' : 'textCN'}>{item?.textCN}</div>
                  <div className={errorWay ? 'textEN textError' : 'textEN'}>{item?.textEN}</div>
                </div>
              ))
            }

          </div>
        </div>
        <div className='SectionName MarginTop20'>
          <div className='sectionLeft'>
            <div className='SectionNameIn'>
              <div className='textName'>您的咨询内容或感兴趣的项目</div>
              <div className='textStar anotherStar'>*</div>
            </div>
            <div className='textNameEN'>Your enquiry</div>
          </div>
          <Box
            sx={{
              width: '670rem',
              height: '150rem',
              marginTop: '20rem',
              marginBottom: '40rem',
            }}
          >
            <ThemeProvider theme={theme}>
              <TextField className='nameTextField' fullWidth id="message" multiline
                rows={6}
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value)
                }}
                color={'primary'}
                type="message"
                name="message"
                // InputLabelProps={{ fontSize: '10px' }}
                FormHelperTextProps={{ sx: { fontSize: '10px' } }}
                InputLabelProps={{ fontSize: '15px' }}
                InputProps={{ sx: { fontSize: '15px', height: '165px', backgroundColor: '#F9F9F9', borderColor: '#E4E4E4', borderRadius: '20rem' } }}
                label={
                  <Typography paragraph variant="body1" fontSize={15} sx={{ flexWrap: 'wrap' }} mt={''}>
                    请简单描述您的情况，至少20个字，谢谢
                  </Typography>
                  // <div className='inquiryInputText'>为了更好给予支持，请简单描述您的情况，至少20个字，谢谢</div>
                }
                error={errorMessage}
                // helperText={errorMessage && 'Please enter Your enquiry'}
                onBlur={() => {
                  setErrorMessage(!checkMessage())
                }}
              // FormHelperTextProps={{ sx: { fontSize: '16px' } }}
              />
            </ThemeProvider>
          </Box>
        </div>
        <div className='viewDivContactUs'>
          <div className='viewContactUs' onClick={send}>
            <div className='textContactUs'>Send</div>
          </div>
        </div>
      </div>
      <Snackbar
        open={showSendSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => { setSendSuccess(false) }}
        message="Your form has been sent, thank you!"
        sx={{ marginTop: '20%' }}
        severity="success"
        autoHideDuration={2000}
      >
        <Alert severity="success" sx={{ width: '100%' }} slotProps={{ closeButton: { fontSize: '30px', height: '60px', width: '60px' }, closeIcon: { fontSize: '32px', height: '60px', width: '60px' } }} action={null}>
          Your form has been sent, thank you!
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div >
  );
}