// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { Component } from 'react';
import "./styles.css";
import { motion } from 'framer-motion';
import ButtonMore from '../../../ButtonMore';
import { setPageState } from '../../../../Store/RootStore';
import { Button } from '@mui/material';
import ContactUsButton from '../../../ContactUsButton';

export default class View1 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isShow } = this.props

    const handleMoreClick = () => {
      setPageState('2');
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    };

    const innerClass = isShow ? 'active' : '';

    return (
      <div className='View2_2' style={{ zIndex: isShow ? 2 : 0 }}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isShow ? 1 : 0 }}
          transition={{ duration: 0.1 }}
          className={`backImageContain`}>
          <img className='backImage' src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-2/background.png' />
          <div className='content'>
            <div onClick={handleMoreClick} className={`viewTitle animation ${innerClass}`} style={{ transform: `translateY(${isShow ? 0 : '-100'}px)`, }}>
              <div className='title'>我们的使命</div>
              <img className='imgTitle' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-2/ourmission.png"></img>
              <div className='viewMore'>{ButtonMore(handleMoreClick)}</div>
            </div>
            <div className={`viewItem animation ${innerClass}`} >
              <img className='imageItem' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-2/card11.png" />
              <div className='textCHN text1'>促进中国海外留学生的身体，心理和情绪健康与人生的全面发展。</div>
              <div className='textENG text11'>To promote physical, mental and emotional well-being among Chinese international students in the UK.</div>

            </div>
            <div className={`viewItem animation ${innerClass}`} >
              <img className='imageItem' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-2/card22.png" />
              <div className='textCHN text2'>帮助中国海外留学生实现学业、职业与个人发展的初衷。</div>
              <div className='textENG text22'>To help Chinese international students achieve their academic aspirations and personal aims that have brought them to the UK.</div>
            </div>
            <div className={`viewItem animation ${innerClass}`} >
              <img className='imageItem' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-2/card33.png" />
              <div className='textCHN text3'>给予中国海外留学生赋能与支持，帮助他们进一步探索人生与自我发现。</div>
              <div className='textENG text33'>To empower and support Chinese international students in their personal growth and self-discovery journey.</div>
            </div>
          </div>
        </motion.div>
        <div className='rightView'>
          {ContactUsButton(false, () => {})}
        </div>
      </div>
    )

  }

}
