// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from 'react';
import './styles.css'
import RenderBottom from '../RenderBottom';
import { useForm, ValidationError } from '@formspree/react';
import { setPageState } from '../../Store/RootStore';

function ContactUsButton(isDialog = false, handleCustomAction) {
  const handlePPClick = () => {
    setPageState("1")
        setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight || document.body.scrollHeight);
        }, 200);
  };

  return (
    <div className='rightContactUs' onClick={isDialog ? handleCustomAction : handlePPClick}>
      <div className='left'>
        <img className='image' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/iconChat.png"></img>
      </div>
      <div className='right'>
        <div className='textcontactus1'>联系我们</div>
        <div className='textcontactus1 textcontactus2'>Contact us</div>
      </div>
    </div>
  );
}
export default ContactUsButton;