// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { useEffect, useState, Component } from "react";
import "./styles.css";
import ViewHeader from "../../View/ViewHeader";
import ViewMenu from "../../View/ViewMenu";
import MobileAboutUs from "../MobileAboutUs"
import MobileHome from "../MobileHome"
import MobileSupport from "../MobileSupport"
import MobileContactUs from "../MobileContactUs"
import MobilePrivatePolicy from "../MobilePrivatePolicy"

export default class MobileIndex extends Component {

  constructor(props) {
      super(props);
      this.state = {
        showMenu: false,
      }
  }

  handleMenuPress = () => {
    const {showMenu} = this.state;
    console.log('xxxx', showMenu);
    this.setState({
      showMenu: !showMenu,
    })
  }

  onItemClick = () => {
    this.setState({
      showMenu: false,
    })
  }

  render() {
    const {pageState} = this.props;
    const {showMenu} = this.state;
    console.log('root', pageState);
    return (
      <div className="viewMobileIndex">
        <ViewHeader onMenuPress={this.handleMenuPress} showMenu={showMenu} />
        {showMenu && <ViewMenu onItemClick={this.onItemClick} onBackClick={this.onItemClick}/>}
        {pageState === '1' && <MobileHome />}
        {pageState === '2' && <MobileAboutUs />}
        {pageState === '3' && <MobileSupport />}
        {pageState === '4' && <MobileContactUs />}
        {pageState === '5' && <MobilePrivatePolicy />}
      </div>
    );
  }

}