// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { useEffect, useState, Component } from "react";
import "./styles.css";
import {
  motion,
  useScroll,
  useSpring,
  useMotionValueEvent
} from "framer-motion";
import View1 from '../View/Views/View1'
import View2 from '../View/Views/View2'
import Stripe from '../View/Stripe'
import ContactUs from '../View/ContactUs'
import SuportServiceView from "../View/SupportServiceView";


function View1Box({ item, index }) {
  return (
    <section key={index}>
      <View1 id={item} item={item} index={index} />
    </section>
  );
}

function View2Box({ item, index }) {
  return (
    <section>
      <View2 id={item} item={item} index={index} />
    </section>
  );
}

function ViewSupport({ item, index, active }) {
  return (
    <section>
      <SuportServiceView id={item} item={item} index={index} active={active} />
    </section>
  );
}

export default function RenderFirst() {
  const { scrollYProgress, scrollY } = useScroll();
  const [active, setActive] = useState(0);

  function isDifferenceWithinThreshold(num) {
    return Math.abs(num - Math.round(num)) < 0.1;
  }

  useMotionValueEvent(scrollY, "change", (latest) => {
    const nowPage = latest / window.innerHeight;
    if (isDifferenceWithinThreshold(nowPage)) {
      setActive(Math.round(nowPage));
    }
    console.log('active', active)

  })
  // const scaleX = useSpring(scrollYProgress, {
  //   stiffness: 100,
  //   damping: 30,
  //   restDelta: 0.001
  // });

  const list = [
    View1Box,
    View2Box,
    ViewSupport,
    ContactUs,
  ]

  return <div className="mainPage">
      {list.map((Item, index) => {
        return (
          <Item id={index} key={index} index={index} active={active}/>
        )
      })}
      {/* <RenderRightPag nowNum={active} length={list.length} /> */}
    </div>
  
}