// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { useEffect, useState, Component } from "react";
import "./styles.css";
import { setPageState } from "../../../Store/RootStore";

export default class ViewHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isPressed: false,
    }
  }

  render() {
    const { onMenuPress, showMenu } = this.props;

    const { isPressed } = this.state;

    const handlePress = () => {
      onMenuPress();
      this.setState({
        isPressed: !isPressed,
      })
    }

    const handleHome = () => {
      setPageState("1");
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    }

    return (
      <div className="viewHeaderMobile">
        <div className="left" onClick={handleHome}>
          <div className="img" >
            <img className="img" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/logo.png" />
          </div>
          <div className="text">
            CISC
          </div>
        </div>
        <div className="right" onClick={handlePress}>
          <img className={showMenu ? "img rightrotate" : "img"} src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/aboutus/select.png" />
        </div>
      </div>
    );
  }

}