import { makeAutoObservable, observable, action, runInAction, asStructure, autorun } from "mobx"

// class RootStore {
//     pageState

//     setPageState = (state) => {
//       this.pageState = state;
//     }

//     constructor(pageState) {
//         makeAutoObservable(this, {
//           pageState: observable,
//           setPageState: action,
//         })
//         this.pageState = pageState;
//     }
// }

// export default new RootStore();

//2
// const store = observable({pageSate: '1'})

// runInAction(() => {
//   console.log('in store', store.pageState);
// })

// export default store;



// export default class RootStore {

//   pageState = "1"

//     constructor(pageState) {
//         makeAutoObservable(this)
//     }
// }


// var React = require('react-native');

export const RootStore = observable({
  /* 一些观察的状态 */
  pageState: "1",
  clientWidth: 0,
  clientHeight: 0,
});


autorun(function () {
  console.log('autoRun pageState', RootStore.pageState);
  console.log('autoRun pageState', RootStore.clientWidth);

});

export const setPageState = action((data) => {
  RootStore.pageState = data;
  console.log('data', data);

  // resize rem
  var docEl = document.documentElement
  var clientWidth = docEl.clientWidth;
  var clientHeight = docEl.clientHeight;

  RootStore.clientWidth = clientWidth;
  RootStore.clientHeight = clientHeight;

  if (clientWidth >750) {
    if (data === '3' || data === '2') {
      docEl.style.fontSize = 45.85 * (clientWidth / 1000) + 'px';
    } else {
      if (clientWidth >750) {
        // docEl.style.fontSize = '100px'; 
        // docEl.style.fontSize = '80px';
        docEl.style.fontSize = 95 * (clientHeight / 1000) + 'px';
      } else {
        docEl.style.fontSize = 1 * (clientWidth / 750) + 'px';
      }
    }
  } else {
    docEl.style.fontSize = 1 * (clientWidth / 750) + 'px';

  }


  if (data === '4') {
    if (clientWidth >=750) {
      setTimeout(() => {
        window.scrollTo(0, document.documentElement.scrollHeight || document.body.scrollHeight);
      }, 200);
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    }
  }

  if (clientWidth <=750) {
    if (data != '1') {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    }
  }

});

export default RootStore;
