// function getUrlParams(name, str) {
//     const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
//     const r = str.substr(1).match(reg)
//     if (r != null) return decodeURIComponent(r[2])
//     return null
// }
// export const RequestUrl = 'http://192.168.1.203:9000/mtxcx';
export const APP_VERSION = '1.8';
// export const RequestUrl = global.baseUrl;
// export const RequestUrl = 'http://ndstest.mindtrip.com';
// export const RequestUrl = 'https://xcx.mindtrip.com/mtxcx';
// export const RequestUrl = 'https://xcx.mindtrip.com/mtxcx';
// export const RequestUrl = 'http://192.168.1.6:5000';
export const RequestUrl = 'https://flask.mindtrip.com';