
// import React, { useState, useEffect } from "react";
import React, { Component } from 'react';
import './style.css'
import RootStore, { setPageState } from '../../Store/RootStore';

export default function RenderTop(handelTabChange) {

    // const [value, setValue] = React.useState('1');

    const handleChange = (newValue) => {
        // setValue(newValue);
        // console.log('newValue', newValue)
        // handelTabChange(newValue);
        // RootStore.pageState = value;
        // console.log('RootStore.pageState',RootStore.pageState);
        setPageState(newValue)
        // 将页面滚动到顶部
        window.scrollTo(0, 0);
    };

    const tabItem = (pageState, content) => (
        <div className='viewTabIn2' onClick={() => handleChange(pageState)}>
            {RootStore.pageState === pageState && <img className="imgSelect" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/tabSelect.png" alt='img' />}
            <div className={RootStore.pageState === pageState ? 'textSelect' : 'textUnSelect'}>{content}</div>
        </div>
    )

    return <div className="header" >
        <div className='headerLeftContainer' onClick={() => handleChange('1')}>
            <div className='headerIcon'><img className='headerIcon' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/logo.png" alt='icon'/></div>
            <div className="headerText">中国海外留学生心理支持中心 CISC</div>
        </div>
        <div className="viewTab">
            {/* <Box sx={{ width: '100%'}}>
                <Tabs
                    // variant="fullWidth"
                    value={RootStore.pageState}
                    // onChange={action(handleChange)}
                    onChange={handleChange}
                    // textColor="secondary"
                    // indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    sx={{width: '4.8rem', flexDirection: 'row', alignItems: 'center', justifyContent: 'end'}}
                >
                    <Tab value="1" label="Home" sx={{ fontSize: '.1rem' }}></Tab>
                    <Tab value="2" label="About us" sx={{ fontSize: '.1rem' }} />
                    <Tab value="3" label="Support & Services" sx={{ fontSize: '.1rem' }} wrapped={false} />
                    <Tab value="4" label="Contact us" sx={{ fontSize: '.1rem' }} />
                </Tabs>
            </Box> */}
            <div className='viewTabIn'>
                {tabItem("1","  Home  ")}
                {tabItem("2","About us")}
                {tabItem("3","Support & Services")}
                {tabItem("4","Contact us")}

                {/* <div className='textUnSelect'>About us</div>
                <div className='textUnSelect'>Support & Services</div>
                <div className='textUnSelect'>Contact us</div> */}
            </div>
        </div>
    </div>;
}

// export default class RenderTop extends Component {

//     constructor(props) {
//         super(props);
//     }

//     handleChange = (event, newValue) => {
//         // RootStore.pageState = newValue;
//         setPageState(newValue)
//         console.log('RootStore.pageState',RootStore.pageState);
//     };

//     render() {
//         return <div className="header" >
//         <div className="headerText">中国海外留学生心理支持中心 CISC</div>
//         <div className="viewTab">
//             <Box sx={{ width: '100%'}}>
//                 <Tabs
//                     // variant="fullWidth"
//                     value={RootStore.pageState}
//                     onChange={action(this.handleChange)}
//                     // textColor="secondary"
//                     // indicatorColor="secondary"
//                     aria-label="secondary tabs example"
//                     sx={{width: '4.8rem', flexDirection: 'row', alignItems: 'center', justifyContent: 'end'}}
//                 >
//                     <Tab value="1" label="Home" sx={{ fontSize: '.1rem' }}></Tab>
//                     <Tab value="2" label="About us" sx={{ fontSize: '.1rem' }} />
//                     <Tab value="3" label="Support & Services" sx={{ fontSize: '.1rem' }} wrapped={false} />
//                     <Tab value="4" label="Contact us" sx={{ fontSize: '.1rem' }} />
//                 </Tabs>
//             </Box>
//         </div>
//     </div>;
//     }

// }