// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { useEffect, useState, Component } from "react";
import "./styles.css";
import RootStore, { setPageState } from "../../../Store/RootStore";

export default class ViewMenu extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { onItemClick, onBackClick } = this.props;

    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    }

    const tabItem = (pageState, content) => (
      <div className='item' onClick={() => { setPageState(pageState); onItemClick(); handleScrollToTop() }}>
        <div className={RootStore.pageState === pageState ? 'textSelect' : 'textUnSelect'}>{content}</div>
        {<img className={RootStore.pageState === pageState ? 'imgSelect' : 'imgUnSelect'} src={RootStore.pageState === pageState ? "https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/home/arrowpurple.png" : 'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/home/arrowblack.png'} alt='img' />}
      </div>
    )

    return (
      <div className="viewMenu" onClick={onBackClick}>
        <div className="viewMenutop"></div>
        {tabItem("1", "主页  Home")}
        {tabItem("2", "关于我们  About us")}
        {tabItem("3", "支持与服务  Support & Services")}
        {tabItem("4", "联系我们  Contact us")}
      </div>
    );
  }

}