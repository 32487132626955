// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { Component } from 'react';
import "./styles.css";
import ContactUsButton from '../../ContactUsButton';
import { setPageState } from '../../../Store/RootStore';

export default class View1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canTou: true,
    };
    this.touTime = 1300;
  }

  handlePPClick = () => {
    setPageState("1")
        setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight || document.body.scrollHeight);
        }, 200);
  };
 
  render() {
    return (
      <div className='View1box' >
        <div className='purplePot'></div>
        <div className='yellowPot'></div>
        <div className='content'>
          <div className='left'>
            <div className='line4'>中国海外留学生心理支持中心</div>
            <div className='line1'>Chinese International Students</div>
            <div className='line2'>Wellbeing Support</div>
            <div className='line3'>Centre</div>
            <div className='line5'>欢迎来到CISC，这里是为中国海外留学生提供全方位心理支持和身心发展的家园！</div>
            <div className='line6 firstline6'>A very warm welcome to CISC! </div>
            <div className='line6'>A caring home for Chinese international students </div>
            <div className='line6'>dedicated to their mental health and personal growth!</div>
            <div className='viewContactUs' onClick={this.handlePPClick}>
              <div className='textContactUs'>Contact us</div>
            </div>
          </div>
          <div className='right'>
            <div className='bigImageContainer'>
              <img className='bigImage' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/home11.png" />
            </div>
          </div>
        </div>
        <div className='rightView'>
          {ContactUsButton(false, () => {})}
        </div>
      </div>
    );
  }
}
