// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { Component } from 'react';
import "./styles.css";
import { motion } from 'framer-motion';
import ButtonMore from '../../../ButtonMore';
import { setPageState } from '../../../../Store/RootStore';
import ContactUsButton from '../../../ContactUsButton';

export default class View1 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { isShow } = this.props

    const innerClass = isShow ? 'active' : '';

    const handleMoreClick = () => {
      setPageState('2');
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    };

    return (
      <div className='View2_1' style={{zIndex: isShow ? 2 : 0}}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isShow ? 1 : 0 }}
          transition={{ duration: 0.1 }}
          className={`backImageContain`}>
          <img className='backImage' src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-1/back.png' />
          <div className='content'>
            <div onClick={handleMoreClick} className={`viewTitle animation ${innerClass}`} style={{ transform: `translateY(${isShow ? 0 : '-100'}px)`, }}>
              <div className='title' >我们是谁</div>
              <img className='imgTitle' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/whoweare/whoweare.png"></img>
            </div>
            <div className={`viewMore animation ${innerClass}`} style={{ transform: `translateY(${isShow ? 0 : '-100'}px)`, }}>{ButtonMore(handleMoreClick)}</div>
            <div className={`viewItem`}>
              <motion.div className='viewImageItem'
                initial={{ opacity: 0 }}
                animate={{ opacity: isShow ? 1 : 0, scale: isShow ? 1 : 1 }}
                transition={{ duration: 1, type: 'spring' }}
              >
                <img className='imageItem' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-1/icon.png" />
              </motion.div>
              <div className='viewRight'>
                <motion.div
                  className='textCHN'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isShow ? 1 : 0, scale: isShow ? 1 : 0.8 }}
                  transition={{ duration: 1, type: 'spring' }}>
                  CISC是一家非营利型社区服务机构，专家团队包括具备多项资格和认证且经验丰富的心理咨询师，生命教练和营养师，致力于给在英留学生提供在文化适应、学业支持和身心健康等方面的心理护航。</motion.div>
                <motion.div className='textENG'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isShow ? 1 : 0, scale: isShow ? 1 : 1 }}
                  transition={{ duration: 1, type: 'spring', delay: 0.3 }}>CISC is a not-for-profit Community Interest Company. We are proud to have a team of international experts from diverse backgrounds who are fully qualified and insured to practice an extensive range of modalities, including psychotherapy, life coaching, and nutritional therapy.</motion.div>
              </div>
            </div>
          </div>
        </motion.div>
        <div className='rightView'>
          {ContactUsButton(false, () => {})}
        </div>
      </div>
    );
  }

}
