// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from 'react';
import './styles.css'
import RenderBottom from '../RenderBottom';
import { useForm, ValidationError } from '@formspree/react';
import { setPageState } from '../../Store/RootStore';

function ButtonMore(handlePPClick) {
  return (
    <div className='viewbuttonMore' onClick={handlePPClick}>
      <div className='left'>
        <div className='text'>查看更多</div>
        <div className='textdiv1'>
          <div className='text1'>more</div>
        </div>
      </div>
      <div className='viewright'>
        <img className='imgright' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-3/arrowthree.png"></img>
      </div>
    </div>
  );
}
export default ButtonMore;