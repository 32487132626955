// Photos from https://citizenofnowhe.re/lines-of-the-city
// import React, { Component } from 'react';
// import { loadStripe } from '@stripe/stripe-js'
// import { Elements, PaymentElement } from '@stripe/react-stripe-js';
// const Stripe = loadStripe('pk_test_51NVOfuHgBkeMt0feOTmDrEJTpdQryZobT6bjCOFUa8TPJTWs2r7rsTehF7iF3NK6K3oGVV122NurRft2DiBXP0Me00rS49uzyb')

// const CheckoutForm = () => {
//     return (
//         <form>
//             <PaymentElement />
//             <button>Submit</button>
//         </form>
//     );
// };

// export default class StripeView extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {};
//         this.options = {
//             // passing the client secret obtained from the server
//             clientSecret: '{{CLIENT_SECRET}}',
//         };
//     }
//     render() {
//         return (
//             <Elements stripe={Stripe} options={this.options}>
//                 <CheckoutForm />
//             </Elements>
//         );
//     }
// }

// https://buy.stripe.com/test_4gw2by9NhfWL6QMcMM
//"id": "plink_1NyWT5L7p4unHP641uFNRBrz",


import React, { useState, useEffect } from "react";
import { APIPost } from '../../API/APIMethod'
import './style.css'

const request = (index) => {
    const list = [
        'price_1NVlmRJDpw2zE1lV5tNL4C5z',
        // 'price_1NyWSsL7p4unHP643k7BkgSw',
        'price_1NVlmRJDpw2zE1lV8P1em9bc',
        'price_1NVlmRJDpw2zE1lVFM1k7PPn',
        'price_1NVr0WJDpw2zE1lVk04B2x7Z'
    ]
    // const path = '/pay/public/createCheckoutSession';
    const path = '/xcxapp/public/createCheckoutSession';
    const params = {
        priceIds: list[index],
        num: 1,
    }
    const onSuccess = (res) => {
        const data = res?.data;
        if (data?.httpStatusCode === 303) {
            window.location.href = data?.url;
        }
    }
    APIPost(path, params)
        .then(onSuccess)
}


const ProductDisplay = () => {
    {/* <div className="product">
            <img
        src="https://i.imgur.com/EHyR2nP.png"
        alt="The cover of Stubborn Attachments"
      />
            <div className="description">
                <h3>Stubborn Attachments</h3>
                <h5>$20.00</h5>
            </div>
        </div> */}
    // <>
    //     <button onClick={request.bind(this, 0)}>
    //     5 磅
    // </button>
    // <button onClick={request.bind(this, 1)}>
    //         10 磅
    //     </button>
    //     <button onClick={request.bind(this, 2)}>
    //         20 磅
    //     </button>
    //     <button onClick={request.bind(this, 3)}>
    //         自定义
    //     </button>
    //     </>

    const array = [
        { id: 0, text: '￡5', url: 'https://donate.stripe.com/6oE01L9VUeY01t6aEE' },
        { id: 1, text: '￡10', url: 'https://donate.stripe.com/6oE3dX2ts6ruefS145' },
        { id: 2, text: '￡20', url: 'https://donate.stripe.com/6oE15P1po9DG5Jm6oq' },
        { id: 3, text: '自定义', url: 'https://donate.stripe.com/cN23dX0lk9DG7RueUX' },
    ];

    


    return (
        <div className="StripeView">
            <div className="ArrayItem">
                {array.map((item) => (StripeItem(item)))}
            </div>
        </div>
    )
}

function StripeItem(item) {

    const [isHovered, setIsHovered] = useState(false);
    const linkto = (url) => {
        window.location.href = url;
    }
    const handleMouseOver = () => {
        setIsHovered(true);
    };
    
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            className={"StripeItem" + (item.id === 3 ? "Last" : "") + (isHovered ? 'hover' : '')}
            // onClick={request.bind(this, item.id)}>
            onClick={linkto.bind(this, item.url)}>
            <div className={"StripeText" +  (isHovered ? 'hover' : '')}>{item.text}</div>
        </div>
    )
}

export default function StripeView() {
    const [message, setMessage] = useState("");

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    return <ProductDisplay />;

}