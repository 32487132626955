// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { useEffect, useState, Component } from "react";
import "./styles.css";
import RenderTop from '../View/RenderTop'
import RenderFirst from '../RenderFirstPage'
import AboutUsPage from '../RenderAboutUsPage';
import SupportPage from '../RenderSupportServicePage';
import ContactPage from '../RenderContactPage';
import PrivatePolicyPage from '../RenderPrivatePolicyPage';

export default class RenderFirstPage extends Component {

  constructor(props) {
      super(props);
  }

  render() {
    const {pageState} = this.props;
    console.log('root', pageState);
    return (
      <>
        <RenderTop/>
        {pageState === '1' && <RenderFirst />}
        {pageState === '2' && <AboutUsPage />}
        {pageState === '3' && <SupportPage />}
        {pageState === '4' && <RenderFirst />}
        {pageState === '5' && <PrivatePolicyPage />}
      </>
    );
  }

}