
import React, { useState, useEffect } from "react";
import './styles.css'
import RootStore, { setPageState } from "../../../Store/RootStore";
import ViewContact from "../../View/ViewContact";

export default function AboutUsPage() {

  const imgsHeight = RootStore.clientWidth * 3144 / 1500;

  const imgs = [
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/aboutus/1.png',
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/aboutus/2.png',
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/aboutus/3.png',
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/aboutus/4.png',
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/aboutus/5.png',
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/aboutus/6.png',
  ];

  return <div className="viewMobileAboutUs" >
    <div className="divImageBack">
      {imgs.map((item) => (
        <div className="backimgonediv" style={{ height: imgsHeight }}>
          <img src={item} className="backimgone" style={{ height: imgsHeight }} />
        </div>
      ))}
    </div>
    <div className="contentText">
      <div className="title">我们是谁</div>
      <div className="textCN text1" >设立在英国伦敦的<span className="textSemibold">中国海外留学生心理支持中心 (CISC)</span> ，是一家非营利型社区服务机构。我们的专家团队包括具备多项资格和认证、经验丰富的心理咨询师，生命教练和营养师，致力于给在英留学生提供在<span className="textSemibold">文化适应，学业支持和身心健康</span>等各个方面的心理护航。</div>
      <div className="textCN text2" >机构的专家和义工团队旨在为广大来自中国的新一代海外留学生提供多种形式的优质的心理支持服务。作为一家非营利机构，我们希望可以为海外学子们提供一个<span className="textSemibold">海外家园和心理港湾。</span></div>
      <div className="textEN text3" ><span className="textENSemibold">Chinese International Students Wellbeing Support Centre (CISC)</span> is a <span className="textENSemibold">not-for-profit</span> Community Interest Company. We are proud to have a team of international experts from diverse backgrounds who are fully qualified and insured to practice an extensive range of modalities, including life coaching, psychotherapy, and nutritional therapy. We are committed to supporting Chinese international students fully, including emotional well-being support in <span className="textENSemibold">cultural adaptation</span> and achieving their <span className="textENSemibold"> academic goals and personal aims.</span>
        <br />
        As a not-for-profit organisation, CISC will serve as a harbour to the Chinese international students’ community and assist them in their emotional health and personal growth. </div>
      <div className="title2">我们的使命</div>
      <div className="textCN text4" >促进中国海外留学生在身体、心理和情绪健康方面的全面发展。</div>
      <div className="textEN text5" >To promote physical, mental and emotional well-being among Chinese international students in the UK.</div>
      <div className="textCN text6" >帮助中国海外留学生实现学业、职业与个人发展的初衷。</div>
      <div className="textEN text7" >To help Chinese international students achieve their academic aspirations and personal aims that have brought them to the UK.</div>
      <div className="textCN text8" >给予中国海外留学生赋能与支持，帮助他们进一步探索人生与自我发现。</div>
      <div className="textEN text81" >To empower and support Chinese international students in their personal growth and self-discovery journey.</div>
      {ViewHowToContactUS()}
      {ViewSecondBottom()}
    </div>
    {ViewContact(1)}
    <div className='rightView'>
      {/* {ContactUsButton(true, () => {setOpen(true)})} */}
    </div>
  </div>;
}




function ViewHowToContactUS() {


  const contackClick = (event) => {
    setPageState('4')
  };


  return (
    <>
      <div className="textHowToContact">如何联系我们？</div>
      <div className="textCN2 text9">如果您是中国海外留学生，目前在学习、生活或个人发展方面遇到一些挑战，同时一些情绪方面的问题令您无法有效地面对这些挑战，不用过分担心，<span className="textClickCN" onClick={contackClick}>请尽快与我们联系</span>。我们一般会在48小时内回复您，并根据您的情况和具体需要，提供最合适您的心理支持服务。</div>
      <div className="textEN2 text10">If you are experiencing a difficult time in your academic or personal life and your emotional health is preventing you from overcoming these difficulties, please do not hesitate to <span className="textClickEN" onClick={contackClick}>reach out to CISC</span>. WWe aim to respond to you within 48 hours and provide the most suitable well-being support services based on your specific needs.</div>
    </>
  );
}

function ViewSecondBottom() {

  const ppclick = (event) => {
    setPageState('5');
    // 将页面滚动到顶部
    window.scrollTo(0, 0);
  };

  const email = 'support@ciscic.org.uk'


  return (
    <>
      <div className="secondbottom">
        {/* <div className="textBottom textbottom1">中国海外留学生心理支持中心 CISC</div>
                <div className="textBottom textbottom2">Chinese International Students Wellbeing Support Centre</div> */}
        <img className="footerIconAddress" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/contact/address.png" />
        <img className="footerIconEmail" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/contact/email.png" />
        <div className="textBottom textbottom3">Email Address:<a className="email" href={`mailto:${email}`}>{email}</a></div>
        <div className="textBottom textbottom4">Address: 8 Northumberland Avenue, London, WC2N 5BY, United Kingdom</div>
        <div className="viewQRCode">
          <div className="viewdivImage">
            <img className="imgQRCode" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/aboutus/qrcode.png" />
          </div>
          <div className="textQRCode">CISC微信公众号</div>
        </div>
        <div className="footerText2 foothover" onClick={ppclick}>Privacy Policy</div>
        <div className="footerText2">
          Copyright © 2019 - 2024 Chinese International Students Well-being Support Centre CIC (Company no.12249552) - All Rights Reserved.
        </div>

      </div>

    </>
  );
}