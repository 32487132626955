// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { Component } from 'react';
import "./styles.css";
import { motion, AnimatePresence } from 'framer-motion';
import ButtonMore from '../ButtonMore';
import { setPageState } from '../../Store/RootStore';
import ContactUsButton from '../ContactUsButton';

export default class SuportServiceView extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isShow : false
    }
  }
  
  render() {
    const { active } = this.props
    console.log('inn active', active);
    const isShow = active === 2;

    console.log('isshow', isShow)

    const innerClass = isShow ? 'active' : '';

    const handleMoreClick = () => {
      setPageState('3');
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    };

    return (

      <div className='ViewSupport' style={{ zIndex: isShow ? 2 : 0 }}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isShow ? 1 : 0 }}
          transition={{ duration: 0.1 }}
          className={`backImageContain`}>
          <img className='backImage' src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-3/back1.png' />
          <div className='content'>
            <div onClick={handleMoreClick}
                className={`viewTitle animation ${innerClass}`} 
                style={{ transform: `translateY(${isShow ? 0 : '-100'}px)`, }}
            >
              <div className='title'>支持与服务</div>
              <img className='imgTitle' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-3/support.png"></img>
              <div className='viewMore'>{ButtonMore(handleMoreClick)}</div>
            </div>
            <div className={`viewItem`}>
              <motion.div className={`viewImageItem1 animation ${innerClass}`} key={1}
              >
                <img className='imageItem1' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-3/1-3-4-new.png" alt='1' key={Date.now().toString() + '1'} />
              </motion.div>
              <motion.div className={`viewImageItem2 animation ${innerClass}`} key={2}
              >
                <img className='imageItem2' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-3/1-3-3-new.png" alt='2' key={Date.now().toString() + '2'} />
              </motion.div>
              <motion.div className={`viewImageItem3 animation ${innerClass}`} key={3}
              >
                <img className='imageItem3' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-3/1-3-2-new.png" alt='3' key={Date.now().toString() + '3'} />
              </motion.div>
              <motion.div className={`viewImageItem4 animation ${innerClass}`} key={4}
              >
                <img className='imageItem4' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-3/1-3-1.png" alt='4' key={Date.now().toString() + '4'} />
              </motion.div>
            </div>
          </div>
        </motion.div>
        <div className='rightView'>
          {ContactUsButton(false, () => {})}
        </div>
      </div>
    );
  }

}
