import { APP_VERSION, RequestUrl } from './Constant'

export function APIPost(path, params) {
  const paramWithToken = {
    ...params,
  };

  // return fetch(RequestUrl + path + '?token=' + global.token, {
  return fetch(RequestUrl + path, {

    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      version: APP_VERSION,
    },
    body: JSON.stringify(paramWithToken)
  })
    .then(res => res.json())
}

export function APIPostForm(path, params) {
  const paramWithToken = {
    token: global.token,
    ...params,
  };
  console.log(paramWithToken);
  console.log(RequestUrl + path);
  return fetch(RequestUrl + path + '?' + strDicToString(paramWithToken), {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      version: APP_VERSION,
    },
    body: JSON.stringify(paramWithToken)
  }).then(res => res.json())
}

export function ApiGet(path, params) {
  const paramWithToken = {
    token: global.token,
    version: 1.9,
    ...params,
  };
  console.log('接口传参', paramWithToken);
  return fetch(RequestUrl + path + '?' + strDicToString(paramWithToken), {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      version: APP_VERSION,
    },
  })
    .then(res => res.json())
}
const strDicToString = (data) => {
  let result = '';
  let arr = [];
  for (let [k, v] of Object.entries(data)) {
    let str = k + '=' + v;
    arr.push(str);
  }
  for (var i = 0; i < arr.length; i++) {
    if (i === arr.length - 1) {
      result += arr[i];
    } else {
      result += arr[i] + '&';
    }
  }
  return result;
};





