
import React, { useState, useEffect } from "react";
import './style.css'


export default function StripeView({ length, nowNum, changeView }) {

    return <div className="pagBox" >
        {new Array(length)?.fill(1)?.map?.((item, index) => {
            const isSel = nowNum === index;
            return (
                <div
                    onClick={changeView.bind(this, index)}
                    key={index}
                    className={`pag ${isSel ? 'pagActive' : ''}`}
                />
            )
        })}
    </div>;

}