// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { Component } from 'react';
import "./styles.css";
import View2_1 from './View2_1'
import View2_2 from './View2_2'
import RenderPag from '../../RenderPag'

export default class View2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      showView: 0,
      index: props.index,
      item: props.item,
      canTou: true,
    };
    this.touTime = 1300;
  }
  changeView = (index) => {
    this.setState({
      showView: index,
    });
  }
  render() {
    const { showView, canTou } = this.state;
    const list = [View2_1, View2_2];
    const { index } = this.props;
    return (
      <div className='View12box' >
        {list.map((Item, i) => {
          const isShow = showView === i;
          const Com = Item;
          return (
            <Com index={i} isShow={isShow} />
          );
        })}
        <div
          className={`itemBtn itemLeft`}
          onClick={() => {
            const tempShowView = showView ? showView - 1 : list.length - 1;
            if (!canTou) {
              return;
            }
            this.setState({
              showView: tempShowView,
              canTou: false,
            }, () => {
              this.timeout = setTimeout(() => {
                clearTimeout(this.timeout);
                this.setState({
                  canTou: true,
                });
              }, this.touTime)
            });
          }}>
            <img className="imageArrow" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-2/arrowleft.png"/>
          </div>
        <div
          className={`itemBtn itemRight`}
          onClick={() => {
            const tempShowView = showView === list.length - 1 ? 0 : showView + 1;
            if (!canTou) {
              return;
            }
            this.setState({
              showView: tempShowView,
              canTou: false,
            }, () => {
              this.timeout = setTimeout(() => {
                clearTimeout(this.timeout);
                this.setState({
                  canTou: true,
                });
              }, this.touTime)
            });
          }}>
          <img className="imageArrow" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/1-2/arrowright.png"/>
          </div>
        <RenderPag changeView={this.changeView} length={list.length} nowNum={showView} />
      </div>
    );
  }

}
