import React, { useState, useEffect } from "react";
import './styles.css'
import { setPageState } from "../../../Store/RootStore";


export default function MobileIndex(handelTabChange) {

    const email = 'support@ciscic.org.uk'


    return <div className="viewMobilePP" >
        <div className="contentText">
            <div className="contentTextStyle" >
                <div className="headerview" onClick={() => setPageState('1')} >
                    <div className="divBack">
                        <img className="imgBack" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/blackback.png"  />
                    </div>
                    <div className="title">隐私政策 Privacy Policy</div>
                </div>
                <div className="titleline"></div>
                <div className="subtitle">INTRODUCTION</div>
                <div className="content">Welcome to Chinese International Students Well-being Support Centre (CISC)’s Privacy Policy.
                    CISC respects your privacy and is committed to protecting your personal data. This privacy notice will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) or when you otherwise provide personal data to us via other means (such as over the telephone).
                </div>
                <div className="subtitle">1. Who is responsible for managing my information?</div>
                <div className="content">This website is owned and maintained by us (CISC).
                    <br />
                    We are responsible for the collection and proper management of any personal information you submit. We will keep your personal details secure and use the information you provide consistently with applicable privacy and data protection laws and the terms of this Privacy Policy.
                </div>
                <div className="subtitle">2. What information do We collect?</div>
                <div className="content">Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:
                    <br />
                    (A)    Identity Data: This includes data relating specifically to your identity, such as your first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.
                    <br />
                    (B)    Contact Data: This includes data relating to how you may be contacted, such as your billing address, delivery address, email address and telephone numbers.
                    <br />(C)    Financial Data: This include data relating to your methods of payment, such as your payment card details.
                    <br />(D)    Transaction Data: This includes data relating to the transactions you have carried out with us, such as details about payments to and from you and other details of products and services you have purchased from us.
                    <br />(E)    Technical Data: This includes more technical data that we may obtain when you make use of our website, such as your internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.
                    <br />(F)    Profile Data: This includes the data that we receive when you create a profile on our website and make use of that data, such as your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.
                    <br />(G)    Usage Data: This includes information about how you use our website, products and services.
                    <br />(H)    Marketing and Communications Data: This includes your preferences in relation to whether or not you want to receive marketing from us and our third parties and also your communication preferences.
                </div>
                <div className="subtitle">3. How do we use your information?</div>
                <div className="content">The information you provide may be used in a number of ways, for example:
                    <br />
                    (A) to provide you with the information, products and services you ask for, and honour any contract you have with us;
                    <br />
                    (B) for statistical purposes when we evaluate our range of services;
                    <br />
                    (C) to personalise repeat visits to our website;
                    <br />
                    (D) to tell you about other products and services we think may be of interest to you (see section 5 below); and
                    <br />
                    (E) to manage customer service queries.
                </div>
                <div className="subtitle">4. Who will we share your information with?</div>
                <div className="content">We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.
                </div>
                <div className="subtitle">5. When can we contact you in the future?</div>
                <div className="content">We would like to send you information about our own products and services, as well as those of selected third parties. We may do this by post, telephone, email or SMS, unless you have told us that you do not wish to be contacted in this way. We will always ask you to confirm in advance that you are happy for us to allow third parties to contact you by email.
                    If you would like to change any of your preferences relating to the way in which we may use your information for direct marketing, then please update your user preferences or send an email to <a className="email" href={`mailto:${email}`}>{email}</a>, or contact us at CISC, 8 Northumberland Avenue, St. James’s, London, WC2N 5BY, United Kingdom
                    <br />
                </div>
                <div className="subtitle">6. How long will we hold your information for?</div>
                <div className="content">We have a system of retention periods in place to ensure that your information is only stored whilst it is required for the relevant purposes or to meet legal requirements. Where your information is no longer required, we will ensure it is disposed of in a secure manner.
                </div>
                <div className="subtitle">7. How do we update this privacy policy?</div>
                <div className="content">We keep our privacy policy under regular review and we will place any updates on our web page. This privacy policy was last updated on 11th October 2019.
                </div>
                <div className="subtitle">8. Who can you contact if you have queries about this privacy policy?</div>
                <div className="content">Please contact us if you have any questions about our privacy policy or information we hold about you. By email: <a className="email" href={`mailto:${email}`}>{email}</a> or write to us at: CISC, 8 Northumberland Avenue, London, WC2N 5BY, United Kingdom.
                </div>
            </div>
        </div>
    </div>;

}