
import React, { useState, useEffect } from "react";
import './style.css'

const ProductDisplay = () => {

    const array = [
        { id: 0, text: '￡5', url: 'https://donate.stripe.com/6oE01L9VUeY01t6aEE' },
        { id: 1, text: '￡10', url: 'https://donate.stripe.com/6oE3dX2ts6ruefS145' },
        { id: 2, text: '￡20', url: 'https://donate.stripe.com/6oE15P1po9DG5Jm6oq' },
        { id: 3, text: '自定义', url: 'https://donate.stripe.com/cN23dX0lk9DG7RueUX' },
    ];

    


    return (
        <div className="StripeViewMobile">
            <div className="ArrayItem">
                {array.map((item) => (StripeItem(item)))}
            </div>
        </div>
    )
}

function StripeItem(item) {

    const [isHovered, setIsHovered] = useState(false);
    const linkto = (url) => {
        window.location.href = url;
    }
    const handleMouseOver = () => {
        setIsHovered(true);
    };
    
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        // <div
        // onTouchStart={handleMouseOver}
        // onTouchEnd={handleMouseLeave}
        //     className={"StripeItem" + (item.id === 3 ? "Last" : "") + (isHovered ? 'hover' : '')}
        //     // onClick={request.bind(this, item.id)}>
        //     onClick={linkto.bind(this, item.url)}>
        //     <div className={"StripeText" +  (isHovered ? 'hover' : '')}>{item.text}</div>
        // </div>
        <div
        onTouchStart={handleMouseOver}
        onTouchEnd={handleMouseLeave}
            className={'StripeItemhover'}
            // onClick={request.bind(this, item.id)}>
            onClick={linkto.bind(this, item.url)}>
            <div className={"StripeTexthover"}>{item.text}</div>
        </div>
    )
}

export default function StripeView() {

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            console.log("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            console.log(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    return <ProductDisplay />;

}