
import React, { useState, useEffect } from "react";
import './style.css'
import { setPageState } from "../../../Store/RootStore";

export default function ViewContact(type) {

    const handleContact = () => {
        if (type == 1) {
            setPageState('4')
        } else {
            window.scrollTo(0, document.documentElement.scrollHeight - 2250 * document.documentElement.clientWidth / 375 || document.body.scrollHeight - 2250 * document.documentElement.clientWidth / 375);
        }
    }

    return (
        <div className="ViewContactRightMobile" onClick={handleContact}>
            <img className="contactimg" src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/mobile/message.png' />
        </div>
    )
}